<template lang="pug">
el-dialog(v-model="model", :title="$t('myAccount')")
  el-tabs(v-model="tab")
    el-tab-pane(:label="$t('myData')", name="myData")
      MyData(@close="close")
    el-tab-pane(:label="$t('changePassword')", name="changePassword")
      ResetPassword(@close="close")
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import MyData from "./MyData.vue";
import ResetPassword from "./ChangePassword.vue";

const props = defineProps<{
  modelValue: boolean;
}>();

const tab = ref("myData");

const emit = defineEmits(["update:modelValue"]);

function close() {
  model.value = false;
}

const model = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(val: boolean) {
    emit("update:modelValue", val);
  },
});
</script>

<style scoped>
.my-account-dialog-content {
  padding: 1rem;
}
</style>

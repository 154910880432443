//  Types used in warehouse, see https://github.com/inctec/elearning-portal/issues/951 for clarification
export const whPrefix = "wh.*";
export const whArticlesPrefix = "wh.article.*";
export const whArticlesMultiple = "wh.article.m";
export const whArticlesSingle = "wh.article.s";
export const whSingleArticles = "wh.singlearticle";

export const clientId = "smx-wh-mgmt";

export const warehouseUserSettings = {
  warehouseFilterKey: "wh.filter.warehouse",
  productionAssetFilter: "wh.filter.asset",
};

export const warehouseProps = {
  warehouse: "warehouse",
  storageLocation: "storageLocation",
};

<template lang="pug">
.flex-col
  h1.mb-4 {{ $t("changePassword") }}
  .flex.justify-center
    smx-form
      smx-form-item(:label="$t('password')")
        el-input(v-model="password", type="password", show-password)
      smx-form-item(:label="$t('repeatPassword')")
        el-input(v-model="passwordRepeat", type="password", show-password)
  .flex.justify-end
    el-button(type="danger", @click="close") {{ $t("close") }}
    el-button(type="primary", @click="save") {{ $t("save") }}
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import { useI18n } from "vue-i18n";
import { useMyAccountStore } from "../../stores/myAccount";
import { patchPassword } from "@shared/services/myAccount";
import { showResponseErrorToUser } from "@shared/util/genericResponseErrors";

const emit = defineEmits(["close"]);

const password = ref("");
const passwordRepeat = ref("");

const { t } = useI18n();

const myAccountStore = useMyAccountStore();

onMounted(() => {
  myAccountStore.initUserData(false);
});

const loading = ref(false);

async function save() {
  if (password.value.length < 8) {
    ElNotification({
      title: t("passwordMinLength"),
      type: "error",
    });
    return;
  }
  if (password.value !== passwordRepeat.value) {
    ElNotification({
      title: t("passwordsDontMatch"),
      type: "error",
    });
    return;
  }
  loading.value = true;

  const response = await patchPassword(myAccountStore.userData.id, {
    password: password.value,
  })
    .then(
      () => {
        ElNotification({
          title: t("success"),
          message: t("passwordChanged"),
        });
      },
      (e) => {
        showResponseErrorToUser(e, t);
      },
    )
    .finally(() => {
      loading.value = false;
    });
}

function close() {
  emit("close");
}
</script>

import { AxiosError, HttpStatusCode } from "axios";
import { ElNotification } from "element-plus";
import { VueElement } from "vue";
import { I18n, useI18n } from "vue-i18n";

export function showResponseErrorToUser(error: AxiosError, t: Function) {
  const errorMessage = getResponseErrorMessage(error, t);
  ElNotification({
    title: t("error"),
    message: errorMessage,
    type: "error",
  });
}

export function getResponseErrorMessage(
  error: AxiosError,
  t: Function,
): string {
  if (error?.response?.status === HttpStatusCode.Forbidden) {
    return t("accessDenied");
  }
  if (error?.response?.data?.["error"]) {
    return error?.response?.data?.["error"];
  }
  return t("genericError");
}

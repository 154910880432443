export const baseRoutes = [
  {
    path: "/my-account",
    name: "myAccount",
    component: () => import("@shared/components/myAccount/MyAccount.vue"),
    meta: {
      appClientId: "smx-my-account",
      breadcrumbs: [
        {
          label: "myAccount",
          route: { name: "myAccount" },
        },
      ],
    },
  },
  {
    path: "/print-twin/:id/:name/:internalId",
    props: true,
    name: "printTwin",
    component: () =>
      import("@shared/components/twinManagement/PrintTwinView.vue"),
    meta: {
      appClientId: "smx-base",
    },
  },
  {
    path: "/twin-redirect/:id",
    props: true,
    name: "redirectTwin",
    component: () =>
      import("@shared/components/twinManagement/TwinRedirect.vue"),
    meta: {
      appClientId: "smx-base",
    },
  },
  {
    path: "/my-account/data",
    name: "myData",
    component: () => import("@shared/components/myAccount/MyData.vue"),
    meta: {
      appClientId: "smx-my-account",
      breadcrumbs: [
        {
          label: "myAccount",
          route: { name: "myAccount" },
        },
        {
          label: "myData",
          route: { name: "myData" },
        },
      ],
    },
  },
  {
    path: "/my-account/change-password",
    name: "changePassword",
    component: () => import("@shared/components/myAccount/ChangePassword.vue"),
    meta: {
      appClientId: "smx-my-account",
      breadcrumbs: [
        {
          label: "myAccount",
          route: { name: "myAccount" },
        },
        {
          label: "changePassword",
          route: { name: "changePassword" },
        },
      ],
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { public: true },
    component: () => import("@shared/basic/404.vue"),
  },
];
